<template>
  <div class="settings">
    <back-button
      :to="$route.meta.back"
      class="settings__back"
    >
      <heading
        level="1"
        class="settings__heading"
      >
        {{ $route.meta.heading }}
      </heading>
    </back-button>
    <x-card
      size="xl"
    >
      <router-view />
    </x-card>
  </div>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import BackButton from '@/components/Interface/BackButton.vue'

export default {
  components: {
    Heading,
    BackButton
  }

}
</script>

<style lang="stylus" scoped>
  .settings
    &__back
      margin-bottom 20px

    &__heading
      margin-bottom 0
</style>
